import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'
/** @jsx jsx */
import { jsx, Container, } from "theme-ui"

import Layout from '../components/layout'
import { mediaQueries } from '../gatsby-plugin-theme-ui/media-queries'

const Projects = ({ data }) => {
  const { siteSettings, projects } = data


  const header = siteSettings?.header

  const seo = siteSettings?.seo

  console.log(projects)

  return (
    <Layout siteSettings={siteSettings} header={header}>
      <GatsbySeo title={seo?.metaTitle} description={seo?.metaDescription} />
      <Container
        sx={{
          pt: 6,
          px: 4,
        }}
      >
        <h1>Projects</h1>

        <div
          sx={{
            display: `grid`,
            gridGap: 4,
            [mediaQueries.lg]: {
              gridTemplateColumns: `repeat(auto-fit, 300px)`,
            },
          }}
        >
          {projects?.edges?.length > 0 &&
            projects.edges.map(({ node }) => {
              console.log(node)
              return (
                <div key={node.id}>
                  <GatsbyImage
                    alt={node.title}
                    image={node.mainImage?.asset?.gatsbyImageData}
                  />
                  <h3>{node.title}</h3>
                  <p sx={{ fontSize: 2, mb: 4 }}>{node.shortDescription}</p>
                  <Link
                    to={node.path}
                    sx={{
                      variant: `buttons.secondary`,
                      textDecoration: `none`,
                      px: 3,
                      py: 2,
                    }}
                  >
                    Learn more
                  </Link>
                </div>
              )
            })}
        </div>
      </Container>
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query ProjectsQuery {
    siteSettings: sanitySiteSettings {
      siteName
      siteUrl
      seo {
        metaTitle
        metaDescription
        ogImages {
          altText
          image {
            asset {
              fluid(maxWidth: 1400, maxHeight: 800) {
                src
              }
            }
          }
        }
      }
      header {
        items {
          _key
          title
          internalLink {
            path {
              current
            }
          }
          externalLink
          relativePath
        }
      }
    }
    projects: allSanityProject {
      edges {
        node {
          id
          title
          shortDescription
          mainImage {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 900
                placeholder: NONE
              )
            }
          }
          path
        }
      }
    }
  }
`